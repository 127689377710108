/**
* @author zjc[beica1@outook.com]
* @date 2021/8/11 13:53
* @description
*   TrendPro.vue of WeTrade
*/
<template>
<PageWithHeader>
  <template #title>
    <t path="indicatorsandsignals_2">Indicators and signals</t>
  </template>
  <div class="px-16">
    <div class="df-middle my-16">
      <img src="/img/membership/cover_pro.jpg" width="64" height="64" style="object-fit: fill;" />
      <div class="flex-1 ml-16">
        <t as="p" path="indicatorsandsignals_17" class="f-lg f-bold mb-8">Trend Pro</t>
        <t as="p" path="indicatorsandsignals_18" class="f-sm" style="line-height: 16px;">
          See the ups and downs of the trend and overbought/oversold areas intuitively
        </t>
      </div>
    </div>
    <Line class="mb-12" />
  </div>
  <t #="{td, t}" custom>
    <Tab
      :tabs="[t('indicatorsandsignals_3') || 'Deacription', t('indicatorsandsignals_4') || 'Signals']"
      class="signal-page-tab px-16" @switch="switchTab" :active="tabIndex" />
  </t>
  <div v-if="tabIndex === 0" class="text f-nm c-text px-16 pt-12 pb-24">
    <img src="/img/membership/content_pro.jpg" style="margin-top: 0;" />
    <t path="indicatorsandsignals_19" multiline #="{td}" custom>
      <p v-for="(p, index) in td" :key="index">{{p}}</p>
    </t>
  </div>
  <TheSignals v-else class="pt-12 pb-24" />
</PageWithHeader>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tab from '@/components/Tab.vue'
import Line from '@/components/Line.vue'
import TheSignals from '@/modules/membership/signal/components/TheSignals.vue'

export default defineComponent({
  name: 'TrendPro',
  components: { TheSignals, Line, PageWithHeader, Tab },
  setup () {
    const tabIndex = ref(0)

    const switchTab = (index: number) => {
      tabIndex.value = index
    }

    return {
      switchTab,
      tabIndex,
    }
  },
})
</script>

<style scoped lang="scss">
.faq-list {
  background: var(--color-light);
  border-radius: 2px;
}

.tips {
  justify-content: flex-end;
  background: url('/img/help/banner@2x.png') center no-repeat;
  background-size: 100% 100%;
  color: var(--color-white);
  line-height: 20px;
  min-height: 64px;
}

.tips-text {
  min-width: 60%;
  max-width: 60%;
}

.signal-page-tab {
  ::v-deep(.active) {
    font-size: 17px;
  }
}

.text {
  line-height: 24px;

  img {
    display: block;
    width: 100%;
    margin: 12px 0;
  }
}
</style>
